body {
  margin: 0;
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(https://assurcheck.ch/wp-content/uploads/2022/04/assurcheck_a_lemrisco_company_lausanne-2.jpg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sv-container-modern {
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif !important;
}
.sv-title {
  font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif !important;
}

.sv-root-modern {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 15px;
  padding-top: 5px;
  border-radius: 5px;
}
